import React from "react";

import ParcoursContext from "../../Context/ParcoursContext";
import moment from "moment/moment";
import axios from "../../utils/axios";
import classes from "./Proposition.module.css";
import { Backdrop } from "@mui/material";
import Card from "../../components/Card/Card";
import {
  Col,
  Flex,
  Row,
  Spin,
  Switch,
  notification,
} from "antd";
import dayjs from "dayjs";
import { alertFunction } from "../../utils/alertFunction";
import SendEmail from "./SendEmail/SendEmail";
import SolidButton from "../../components/SolidButton/SolidButton";

export default function Proposition(props) {
    const { setDisabledBtn ,toggle ,modal,setModal , createHistory} = props;
  const [checked, setChecked] = React.useState(false);
  const [pdf, setPdf] = React.useState();

  const {
    souscripteur,
    setCustomerInformations,
    proposition,
   
    openbackdrop,
    brandProperties,
    setOpenbackdrop,
  } = React.useContext(ParcoursContext);
  const [propositions, setPropositions] = React.useState();
  React.useEffect(() => {
    setDisabledBtn(true)
    let customerInformations = [];
    souscripteur.forEach((val) => {
      customerInformations.push({
        number: 476819 + val?.relationship_rank,
        primary_subscriber: val?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: dayjs(val.dn).format("DD/MM/YYYY"),
        },
        address: {
          zip_code: souscripteur[0]?.cp,
          code_insee: souscripteur[0]?.code_insee,
        },
        job: {
          csp_insee: "Toutes",
          regime: "Régime général",
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
          product:
            proposition?.product_range === "PJ VIE PRIVE"
              ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
              : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
        },
        relationship: val?.relationship,
        relationship_rank: val?.relationship_rank,
      });
    });
    let productsIds = {};

    for (let i = 0; i < customerInformations.length; i++) {
      productsIds[0] = Array(customerInformations.length).fill([
        parseInt(process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT),
      ]);
      productsIds[1] = Array(customerInformations.length).fill([
        process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE,
      ]);
    }
    sessionStorage.setItem("globalData", JSON.stringify(customerInformations));

    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/pricing/get_mass_pricing`,
        {
          business_introducer: {
            login: "2556",
          },
          third_app: {
            name: "ASolutions",
            id_external: 476820,
          },
          customer_information: customerInformations,
          contract_information: {
            effective_date: moment()
              .add(1, "month")
              .startOf("month")
              .format("DD/MM/yyyy"),
            mode_splitting: "Mensuel",
          },
          products: productsIds,
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
          setDisabledBtn(false)
          setPropositions(res.data);
          setCustomerInformations(customerInformations);
        
      })
      .catch((err) => {
        setDisabledBtn(true);
        console.log(err)});
    var listProspects = [];

    souscripteur
      .filter((element) => element?.relationship_rank >= 2)
      .forEach((element) => {
        listProspects = [
          ...listProspects,
          {
            name: element?.nom,
            surname: element?.prenom,
            DN: dayjs(element?.dn).format("YYYY-MM-DD"),
            id: "",
            id_opp: "",
            campagne_id: "",
            post: "",
            interlocuteur: "",
            ville: souscripteur[0]?.ville,
            situation: element?.relationship === "married" ? "married" : "",
            subs_tag:
              element?.relationship_rank === 2
                ? "Conjoint"
                : "Enfant" + (element?.relationship_rank - 10),
            users_nom: "",
            users_prenom: "",
            adresse_mail: "",
            civilite: element?.civility === "Monsieur" ? "Mr" : "Mme",
            prospects_civ: "",

            prospects_situation: "",
            nb_enfants: "",
            adresses_adresse: "",
            streetNumber: "",
            CP: souscripteur[0]?.cp,
            streetName: souscripteur[0]?.adresse,
            adresses_ville: "",
            complement_adr: "",
            tel: "",
            tel2: "",
            mobile: "",
            first_campagne: "",
            description: "",
            etat: "",
            lat: "",
            latitude: null,
            longitude: null,
            commercieux_id: "",
            commentaire: "",
            geom: "",
            tags: [],
            lng: "",
            date_naiss: "",
            collab: "",
            id_pros: "",
            id_camp: "",
            coment_plus: "",
            code: "",
            rue: "",
            numero: "",
            complement: "",
            mail: "",
            nb_enfant: "",
            infcomp: "",
            relation:
              element?.relationship === "married" ? "Conjoint" : "Descendant",
            pays: "",
            invalidForms: [],
            deleted: false,
            information_bancaire: {
              id: "",
              titulaire_compte: "",
              iban: "",
              bic_swift: "",
              type_iban: "",
              nom_banque: "",
            },
            prospects_id: "",
          },
        ];
      });

    let offre = {
      info_specifiques_campagnes: {},
      info_specifiques_ligne_produit: {},
      prospects: {
        listProspects: listProspects,
        mainProspect: {
          interlocuteur: null,
          tags: [],
          invalidForms: [],
          id: null,
          prospects_id: null,
          post: null,
          ville: souscripteur[0]?.ville,
          situation: null,
          adresse_mail: souscripteur[0]?.mail,
          civilite: souscripteur[0]?.civility === "Monsieur" ? "Mr" : "Mme",
          name: souscripteur[0]?.prenom,
          surname: souscripteur[0]?.nom,
          DN: dayjs(souscripteur[0]?.dn).format("YYYY-MM-DD"),
          nb_enfants:
            souscripteur[1]?.relationship === "married"
              ? souscripteur.length - 2
              : souscripteur.length - 1,
          streetNumber: null,
          longitude: null,
          latitude: null,
          CP: souscripteur[0]?.cp,
          streetName: souscripteur[0]?.adresse,
          complement_adr: null,
          tel: souscripteur[0]?.phone,
          tel2: null,
          mobile: null,
          description: null,
          relation: "principal",
          subs_tag: "Prospect",
        },
      },
    };
    axios
      .post(`${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/offre/add`, offre, {
        headers: {
          idSession: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        sessionStorage.setItem("SessionURLID", res.data?.id_opportunite_md5);
        sessionStorage.setItem("id_prospect", res.data?.id_prospect_md5);
        sessionStorage.setItem(
          "_subs_tags",
          JSON.stringify(res.data?._subs_tags)
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const generateDevis = (type) => {
    let principalSous = souscripteur.find((e) => e.relationship_rank === 1);
    if (proposition.product_range) {
      notification.destroy();

      setOpenbackdrop(true);
      let customerInfo = [
        {
          number: 1,
          primary_subscriber: true,
          insured: true,
          beneficiary: false,
          birth_info: {
            date: dayjs(principalSous?.dn).format("DD/MM/YYYY"),
          },
          address: {
            street_name: principalSous?.adresse,
            zip_code: principalSous?.cp,
            city: principalSous?.ville,
          },
          job: {
            csp_insee: "Toutes",
            regime: "Régime général",
          },
          contract_information: {
            number: 476820,
            product:
              proposition?.product_range === "PJ VIE PRIVE"
                ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
                : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
          },
          relationship: "primary subscriber",
          relationship_rank: 1,
          identity: {
            use_name: principalSous?.nom,
            first_name: principalSous?.prenom,
          },
          phone: [
            {
              type: "Mobile",
              country_prefix: principalSous?.country_prefix,
              number: principalSous?.phone,
            },
          ],
        },
      ];
      axios
        .post(
          `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/devis/generate_devis`,
          {
            id_opp: sessionStorage.getItem("SessionURLID"),
            document_produit: "protection_juridique_vie_prive",
            data_pricing: {
              option: [],
              third_app: {
                name: "ASolutions",
                id_external: 476820,
              },
              customer_information: customerInfo,
              contract_information: {
                effective_date: moment()
                  .add(1, "month")
                  .startOf("month")
                  .format("DD/MM/yyyy"),
                mode_splitting: "Mensuel",
              },
            },
            data_fields: {
              email: souscripteur[0]?.mail,
              civilite: souscripteur[0]?.civility === "Monsieur" ? "Mr" : "Mme",
              opt_premium:
                proposition?.product_range === "PJ VIE PRIVE" ? "Non" : "Oui",
            },
          },
          {
            headers: {
              apiKey: localStorage.getItem("api_key"),
            },
          }
        )
        .then((response) => {
       
          createHistory(type,response?.data?.devis)
          setPdf(response?.data?.devis);
     
        })
        .catch((err) => {
          console.log(err);
          setOpenbackdrop(false);
        });
    } else {
      alertFunction("Vous devez choisir une proposition.", "error");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 4rem",
        // border: "1px solid #E4E4E5",
        // padding: "1rem 2rem",
        // borderRadius: 13,
      }}
    >
      <SendEmail
        toggle={toggle}
        modal={modal}
        setModal={setModal}

        url={"/devis/"}
        devis={pdf}
        generateDevis={generateDevis}
        setOpenbackdrop={setOpenbackdrop}
      />
      {/* <Divider orientation="center"> */}
        <p
          className={classes.propositionText}
          style={{ color: brandProperties.secondColor }}
        >
          Tarification
        </p>
      {/* </Divider> */}
      <div>
        {propositions ? (
          <div className={classes.box}>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1.5rem",
                marginBottom: "3rem",
              }}
            >
              <span
                className={
                  checked === true
                    ? classes.mensuelle
                    : classes.mensuelleChecked
                }
                style={{
                  color:
                    checked === true ? "#a4a4a4" : brandProperties.secondColor,
                }}
              >
                {" "}
                Cotisation Mensuelle
              </span>
              <Switch
                onChange={() => setChecked(!checked)}
                checked={checked}
                style={{
                  marginInline: "1rem",
                }}
                id="switch_cotisation"
              />
              <span
                className={
                  checked === true ? classes.annuelle : classes.annuelleChecked
                }
                style={{
                  color:
                    checked === true ? brandProperties.secondColor : "#a4a4a4",
                }}
              >
                {" "}
                Cotisation Annuelle
              </span>
            </Row>

            <Row className={classes.propositionBoxs} gutter={24}>
              {propositions.data.map((val, index) => {
                return proposition.product_range === val.product_range ? (
                  <Card
                    key={index}
                    index={index}
                    val={val}
                    selected={true}
                    checked={checked}
                  />
                ) : (
                  <Card
                    key={index}
                    index={index}
                    val={val}
                    selected={false}
                    checked={checked}
                  />
                );
              })}
            </Row>
            <Flex justify="space-evenly" style={{ width: "100%" }}>
              <Col>
                <SolidButton
                  id="BTN_exporter_devis"
                  onClick={() => generateDevis("exporter")}
                  text={
                    <>
                      <i
                        className="fa-regular fa-file-lines"
                        style={{ marginRight: "0.5rem", fontSize: "21px" }}
                      ></i>
                      Exporter PDF
                    </>
                  }
                  styles={{
                    border: `1px solid #26CB87`,
                    width: "11rem",
                    fontWeight: "600",
                    marginBlock: "2rem",
                  }}
                  size="large"
                  colorPrimary="#EAFAF3"
                  textColor={"#26CB87"}
                />
              </Col>
              <Col>
                <SolidButton
                  onClick={() => {
                    generateDevis("mail");
                  }}
                  id="BTN_envoyer_devis_email"
                  text={
                    <>
                      <i
                        className="fa-regular fa-paper-plane"
                        style={{ marginRight: "0.5rem", fontSize: "21px" }}
                      ></i>
                      Envoyer E-mail
                    </>
                  }
                  styles={{
                    border: `1px solid #26CB87`,
                    width: "11rem",
                    fontWeight: "600",
                    marginBlock: "2rem",
                  }}
                  size="large"
                  colorPrimary="#EAFAF3"
                  textColor={"#26CB87"}
                />
              </Col>
            </Flex>
              {/* <Divider /> */}
          </div>
        ) : (
          <div
            style={{
              paddingBlock: "3rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>


      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openbackdrop}
      >
        <div className={classes.loaderContainer}>
          <div
            style={{
              paddingBlock: "3rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>
    </div>
  );
}
