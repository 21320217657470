import React from "react";
// import { Button, Col, Container, Row } from "reactstrap";
import ParcoursContext from "../../../Context/ParcoursContext";
import Familymember from "./Familymember/Familymember";
import classes from "./FamilyComposition.module.css";
import SolidButton from "../../../components/SolidButton/SolidButton";
import { alertFunction } from "../../../utils/alertFunction";
import dayjs from "dayjs";
import TitlePage from "../../../components/TitlePage/TitlePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "antd";
function FamilyComposition() {
  const { souscripteur, setSouscripteur,brandProperties } = React.useContext(ParcoursContext);

  const onAddChild = () => {
    let souscripteurs = souscripteur;
    let temp = [...souscripteurs];
    let nb = souscripteurs.filter(
      (item) => item.relationship === "children"
    )?.length;
    if (nb < 6) {
      temp.push({
        nom: "",
        prenom: "",
        dn: "",
        civility: "",
        relationship: "children",
        relationship_rank: 11 + nb,
        primary_subscriber: false,
      });
      setSouscripteur(temp);
    } else {
      alertFunction(
        "Nous vous informons que le nombre maximum d'enfants à assurer est de 6.",
        "error"
      );
    }
  };
  const onAddConjoint = () => {
    let souscripteurs = souscripteur;
    let temp = [...souscripteurs];
    !temp.find((item) => item.relationship === "married") &&
      temp.push({
        nom: "",
        prenom: "",
        dn: "",
        civility: "",
        relationship: "married",
        relationship_rank: 2,
        primary_subscriber: false,
      });
    setSouscripteur(temp);
  };
  return (
    <div className={classes.memberRow}>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "1rem",
        }}
      >
        <Col >
          <TitlePage img={
              <i className="fa-duotone fa-family" style={{fontSize:"1.75rem", color:brandProperties.secondColor}}></i>} title="Composition Familiale" />
        </Col>
        <Col className={classes.newChildButton}>
          <Row gutter={24}>
            <Col>
              <SolidButton
                onClick={onAddConjoint}
                icon={<FontAwesomeIcon icon={faSquarePlus} />}
                text="Ajouter Conjoint(e)"
                styles={{ border: `1px solid ${brandProperties.principalColor}`,
                fontWeight: "600" }}
                size="large"
                colorPrimary="#FFEFEF"
                textColor={brandProperties.principalColor}
                id="BTN Ajouter Conjoint(e)"
              />
            </Col>
            <Col>
              <SolidButton
                onClick={onAddChild}
                icon={<FontAwesomeIcon icon={faSquarePlus} />}
                text="Ajouter Enfant"
                styles={{ border:`1px solid ${brandProperties.principalColor}`,fontWeight: "600" }}
                size="large"
                colorPrimary="#FFEFEF"
                textColor={brandProperties.principalColor}
                id="BTN Ajouter Enfant"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        {souscripteur
          .filter((item) => item.relationship === "married")
          ?.map((nb, index) => {
            return (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                <Familymember
                  souscripteur={souscripteur}
                  setSouscripteur={setSouscripteur}
                  count={1}
                  name="Conjoint(e)"
                  item={nb}
                  dn={nb?.dn}
                  nom={nb?.nom}
                  prenom={nb?.prenom}
                  civility={nb?.civility}
                  disabledDate={(current) => {
                    return (
                      current && current.isAfter(dayjs().subtract(0, "years"))
                    );
                  }}
                />
              </Col>
            );
          })}

        {souscripteur
          .filter((item) => item.relationship === "children")
          ?.map((nb, index) => {
            return (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                <Familymember
                  souscripteur={souscripteur}
                  setSouscripteur={setSouscripteur}
                  count={index + 2}
                  name={`Enfant ${index + 1}`}
                  item={nb}
                  dn={nb?.dn}
                  nom={nb?.nom}
                  prenom={nb?.prenom}
                  civility={nb?.civility}
                  disabledDate={(current) => {
                    return (
                      current && current.isAfter(dayjs().subtract(0, "years"))
                    );
                  }}
                />
              </Col>
            );
          })}
      </Row>
    </div>
  );
}

export default FamilyComposition;
