import axios from "../../../utils/axios";
import React from "react";
import ParcoursContext from "../../../Context/ParcoursContext";
import classes from "./Souscripteur.module.css";
import locale from "antd/es/date-picker/locale/fr_FR";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import dayjs from "dayjs";
import { PhoneOutlined } from "@material-ui/icons";
import TitlePage from "../../../components/TitlePage/TitlePage";
function Souscripteur() {
  const {
    souscripteur,
    setSouscripteur,
    free,
    setFree,
    villeOptions,
    setVilleOptions,
    setDestination,
    form,
    brandProperties
  } = React.useContext(ParcoursContext);
  const genderOptions = [
    { label: "M", value: "Monsieur" },
    { label: "Mme", value: "Madame" },
  ];
  const getVilles = (val) => {
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/groupe_villes/get_villes_by_code_postal`,
        {
          postal_code: val,
        }
      )
      .then((res) => {
        const formattedOptions = res.data?.villes?.map((ville) => ({
          id: ville.id,
          value: ville.nom_comm,
          label: ville.nom_comm,
        }));
        if (res?.data?.error === true) {
          let temp = [...souscripteur];
          temp[0].ville = "";
          temp[0].code_insee = "";
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ville: "",
          });
          setSouscripteur(temp);
          setVilleOptions([]);
          setFree(true);
        } else {
          setVilleOptions(formattedOptions);
          let temp = [...souscripteur];
          temp[0].ville = formattedOptions[0]?.label;
          temp[0].code_insee = formattedOptions[0]?.id;
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ville: formattedOptions[0]?.value,
          });
          setSouscripteur(temp);
          setFree(false);
        }
      })
      .catch((err) => {
        let temp = [...souscripteur];
        temp[0].ville = "";
        temp[0].code_insee = "";
        form.setFieldsValue({
          ...form.getFieldsValue(),
          ville: "",
        });
        setSouscripteur(temp);
        setVilleOptions([]);
        setFree(true);
        console.log(err);
      });
  };

  return (
    <div>
      <TitlePage img={<i className="fa-duotone fa-user" style={{fontSize:"1.75rem", color:brandProperties.secondColor}}></i>} title={"Souscripteur"} />
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Civilité" required>
            <Select
              id="civilite"
              size="large"
              defaultValue={souscripteur[0]?.civility}
              options={genderOptions}
              onSelect={(option) => {
                let news = [...souscripteur];
                news[0].civility = option;
                setSouscripteur(news);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Nom"}  required>
            <Input
             id="nom"
              defaultValue={souscripteur[0]?.nom}
              size="large"
              onChange={(e) => {
                let news = [...souscripteur];
                news[0].nom = e.target.value;
                news[0].nomDebiteur = news[0].nom + " " + news[0].prenom;
                setSouscripteur(news);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Prénom"} required >
            <Input
             id="prenom"
              defaultValue={souscripteur[0]?.prenom}
              size="large"
              onChange={(e) => {
                let news = [...souscripteur];
                news[0].prenom = e.target.value;
                news[0].nomDebiteur = news[0].nom + " " + news[0].prenom;
                setSouscripteur(news);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            required
            label="Date de naissance"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <DatePicker
              id="date_naissance"
              format={"DD/MM/YYYY"}
              locale={locale}
              disabledDate={(current) => {
                return current && current.isAfter(dayjs().subtract(0, "years"));
              }}
              placeholder="jj/mm/aaaa"
              style={{ width: "100%" }}
              size="large"
              type="date"
              defaultValue={
                souscripteur.find((e) => e.relationship_rank === 1)?.dn
                  ? dayjs(
                    souscripteur.find((e) => e.relationship_rank === 1)?.dn
                  )
                  : ""
              }
              onChange={(e) => {
                let news = [...souscripteur];
                news[0].dn = e;
                setSouscripteur(news);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Adresse"} required>
            <Input
              id="adresse"
              defaultValue={souscripteur[0]?.adresse}
              size="large"
              onChange={(e) => {
                let news = [...souscripteur];
                news[0].adresse = e.target.value;
                setSouscripteur(news);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            required
            label="Code postal"
            maxLength={5}
            rules={[
              {
                pattern: new RegExp(/^.{0,5}$/),
                message: "Le code postale doit être au moins 5 chiffres.",
              },
            ]}
          >
            <Input
             id="code_postal"
              size="large"
              defaultValue={souscripteur[0]?.cp}
              maxLength={5}
              onChange={(e) => {
                if (e.target.value.length === 5) {
                  getVilles(e.target.value);
                } else {
                  let temp = [...souscripteur];
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    ville: "",
                  });
                  temp[0].ville = "";
                  temp[0].code_insee = "";
                  setSouscripteur(temp);
                  setVilleOptions([]);
                  setFree(true);
                }
                let news = [...souscripteur];
                news[0].cp = e.target.value.replace(/[^0-9]/g, "");
                setSouscripteur(news);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="ville"
            label="Ville"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Select
              id="ville"
              size="large"
              options={villeOptions}
              defaultValue={souscripteur[0]?.ville}
              disabled={souscripteur[0].ville === ""}
              onSelect={(option) => {
                if (free !== true) {
                  let news = [...souscripteur];
                  let ville_selected = villeOptions.filter(
                    (element) => element.label === option
                  );
                  news[0].code_insee = ville_selected[0]?.id;
                  news[0].ville = option;
                  setSouscripteur(news);
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Email"} required>
            <Input
              id="email"
              size="large"
              defaultValue={souscripteur[0]?.mail}
              onChange={(e) => {
                let news = [...souscripteur];
                news[0].mail = e.target.value;
                setSouscripteur(news);
                setDestination(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Numéro de téléphone"} required>
            <Space.Compact style={{ width: "100%" }}>
              <Input
                id="prefix_téléphone"
                size="large"
                className={classes.antdInput}
                onChange={(e) => {
                  let news = [...souscripteur];
                  news[0].country_prefix = e.target.value.replace(
                    /[^+0-9]/g,
                    ""
                  );
                  setSouscripteur(news);
                }}
                defaultValue={souscripteur[0]?.country_prefix}
                style={{ width: "20%" }}
                value={souscripteur[0]?.country_prefix}
              />
              <Input
                id="num_téléphone"
                style={{ width: "80%" }}
                addonAfter={<PhoneOutlined style={{ color: "black" }} />}
                size="large"
                onChange={(e) => {
                  let news = [...souscripteur];
                  news[0].phone = e.target.value.replace(/[^0-9]/g, "");
                  setSouscripteur(news);
                }}
                value={souscripteur[0]?.phone}
                defaultValue={souscripteur[0]?.phone}
              />
            </Space.Compact>
          </Form.Item>
        </Col>
      </Row>

      {/* <FloatButton
        type="primary"
        icon={<ClearOutlined />}
        tooltip={<div>Remise à zero</div>}
        onClick={() => {
          navigate(`/?partnership_id=${localStorage.getItem("api_key")}`);
          sessionStorage.clear();
          window.location.reload();
        }}
      /> */}
    </div>
  );
}

export default Souscripteur;
