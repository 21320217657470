import { Button, ConfigProvider } from "antd";
import React from "react";

const SolidButton = (props) => {
  const {
    text,
    colorPrimary,
    textColor,
    icon,
    size,
    onClick,
    styles,
    shape,
    underLine,
    disabled,
    id
  } = props;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorPrimary,
          colorTextLightSolid: textColor,
        },
        components: {
          Button: {
            primaryShadow: "0 0px 0 rgba(5, 145, 255, 0.1)",
            opacityImage: "0.4",
          },
        },
      }}
    >
      {text ? (
        <Button
          disabled={disabled}
          shape={shape}
          style={styles}
          onClick={onClick}
          type="primary"
          size={size}
          icon={icon}
          id={id}
        >
          <span
            style={
              underLine
                ? { textDecoration: "underline", textUnderlineOffset: "3px" }
                : {}
            }
          >
            {text}
          </span>
        </Button>
      ) : (
        <Button
          disabled={disabled}
          shape={shape}
          style={styles}
          onClick={onClick}
          type="primary"
          size={size}
          icon={icon}
          id={id}
        />
      )}
    </ConfigProvider>
  );
};

export default SolidButton;
