import React from "react";
// import { Form, Label, Input } from "reactstrap";
import classes from "./Familymember.module.css";
import { Collapse, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChildren,
  faRestroom,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import ParcoursContext from "../../../../Context/ParcoursContext";
const { Panel } = Collapse;
function FamilyMember(props) {
  const {
    count,
    name,
    souscripteur,
    setSouscripteur,
    item,
    dn,
    nom,
    prenom,
    disabledDate,
    civility,
  } = props;
  const {brandProperties } = React.useContext(ParcoursContext);
  const ageHandler = (e) => {
    let tempCi = [...souscripteur];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.dn = e;

    setSouscripteur(tempCi);
  };
  const nomHandler = (e) => {
    let tempCi = [...souscripteur];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.nom = e.target.value;
    setSouscripteur(tempCi);
  };
  const prenomHandler = (e) => {
    let tempCi = [...souscripteur];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.prenom = e.target.value;
    setSouscripteur(tempCi);
  };

  const civiliteHandler = (e) => {
    let tempCi = [...souscripteur];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.civility = e;
    setSouscripteur(tempCi);
  };
  const genderOptions = [
    { label: "M", value: "Monsieur" },
    { label: "Mme", value: "Madame" },
  ];
  const icon = () => {
    switch (count) {
      case 1:
        return (
          <FontAwesomeIcon
            style={{ color:brandProperties.principalColor, fontSize: 28 }}
            icon={faRestroom}
          />
        );

      default:
        return (
          <FontAwesomeIcon
            style={{ color:brandProperties.principalColor, fontSize: 28 }}
            icon={faChildren}
          />
        );
    }
  };
  const deleteHandler = () => {
    let temp = [...souscripteur];
    let temp2 = temp.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.forEach((a, index) => {
      if (
        a.relationship_rank > item?.relationship_rank &&
        item?.relationship_rank !== 2
      ) {
        a.relationship_rank = a.relationship_rank - 1;
      }
    });
    temp.splice(temp.indexOf(temp2), 1);
    setSouscripteur(temp);
  };
  return (
    <Collapse
      defaultActiveKey={["0"]}
      expandIconPosition="end"
      className={classes.container}
    >
      <Panel
        key="1"
        header={
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginInline: "2rem",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {icon()}

              <h5 className={classes.titre} style={{color:brandProperties.secondColor}}> {name} </h5>
            </div>

            <h5 className={classes.titre} style={{color:brandProperties.secondColor}}>
              {dn &&
                `${
                  dayjs(Date.now()).format("YYYY") - dayjs(dn).format("YYYY")
                } ans`}
            </h5>

            <SolidButton
              styles={{ border: `1px solid ${brandProperties.principalColor}`}}
              colorPrimary="#FFEFEF"
              textColor={brandProperties.principalColor}
              onClick={deleteHandler}
              icon={<FontAwesomeIcon icon={faTrash}  />}
              id={`BTN Supprimer ${name}`}
           
            />
          </Row>
        }
      >
        <Form.Item label="Civilité" required>
          <Select
            size="large"
            id={`Civilte ${name}`}
            defaultValue={civility}
            options={genderOptions}
            onSelect={civiliteHandler}
          />
        </Form.Item>

        <Form.Item label={"Nom"} required>
          <Input defaultValue={nom} size="large" onChange={nomHandler} id={`Nom ${name}`} />
        </Form.Item>

        <Form.Item label={"Prénom"} required>
          <Input defaultValue={prenom} size="large" onChange={prenomHandler} id={`Prénom ${name}`} />
        </Form.Item>

        <Form.Item
          required
          label="Date de naissance"
          rules={[
            {
              required: true,
              message: "Veuillez remplir ce champ.",
            },
          ]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            id={`Date naissance ${name}`}
            // locale={locale}
            locale={locale}
            placeholder="jj/mm/aaaa"
            style={{ width: "100%" }}
            disabledDate={disabledDate}
            size="large"
            type="date"
            defaultValue={
              souscripteur.find(
                (e) => e.relationship_rank === item.relationship_rank
              )?.dn
                ? dayjs(
                    souscripteur.find(
                      (e) => e.relationship_rank === item.relationship_rank
                    )?.dn
                  )
                : ""
            }
            onChange={(e) => ageHandler(e)}
            // disabledDate={disabledDate}
          />
        </Form.Item>
      </Panel>
    </Collapse>
    // </Form>
  );
}

export default FamilyMember;
