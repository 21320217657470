import React from "react";
// import { Col, Label, Input } from "reactstrap";
import ParcoursContext from "../../Context/ParcoursContext";
import { Col, Form, Input, Select } from "antd";
function InputComponent({ label, value, name, disabled, type, required }) {
  const { souscripteur, setSouscripteur } = React.useContext(ParcoursContext);
  const datePrelevementOptions = [
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
  ];
  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <Form.Item label={label} required={required}>
        {type === "text" ? (
          <Input
            id={name}
            defaultValue={value || ""}
            disabled={disabled}
            size="large"
            onChange={(e) => {
              let tab = [...souscripteur];
              tab[0][name] = e.target.value;
              setSouscripteur(tab);
            }}
          />
        ) : (
          <Select
          id={name}
            size="large"
            defaultValue={value || ""}
            options={datePrelevementOptions}
            onSelect={(option) => {
              let tab = [...souscripteur];
              tab[0][name] = option;
              setSouscripteur(tab);
            }}
          />
        )}
      </Form.Item>

      {/*    
      // <Input
      //   type={type}
      //   id={name}
      //   name="nom"
      //   value={value || ""}
      //   disabled={disabled}
      //   onChange={(e) => {
      //     let tab = [...souscripteur];
      //     tab[0][name] = e.target.value;
      //     setSouscripteur(tab);
      //   }}
      // >
      //   {type === "select" ? (
      //     <>
      //       <option value="5">5</option>
      //       <option value="10">10</option>
      //       <option value="15">15</option>
      //     </>
      //   ) : null}
      </Input> */}
    </Col>
  );
}

export default InputComponent;
