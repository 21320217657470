import React from "react";
import ParcoursContext from "../../Context/ParcoursContext";
import classes from "./Card.module.css";
import iconPjViePrivee from "../../assets/icons/pjViePrivee.svg";
import iconPjViePriveePremium from "../../assets/icons/pjViePriveePremium.svg";
import { Col, Row } from "antd";
function Card({ val, selected, checked }) {
  const {
    setProposition,
    customerInformations,
    setCustomerInformations,
    brandProperties,
  } = React.useContext(ParcoursContext);
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={12}
      xl={12}
      className={classes.pack}
      onClick={() => {
        setProposition(val);
        sessionStorage.setItem("proposition", JSON.stringify(val));
        // let newData = [...customerInformations];
        // newData?.map((e) => {
        //   e.contract_information = {
        //     ...e.contract_information,
        //     product:
        //       val?.product_range === "PJ VIE PRIVE"
        //         ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
        //         : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
        //   };
        // });

        let newData = [];
        customerInformations.map((e) =>
          newData.push({
            ...e,
            contract_information: {
              ...e.contract_information,
              product: [
                val?.product_range === "PJ VIE PRIVE"
                  ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
                  : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
              ],
            },
          })
        );
        setCustomerInformations(newData);
        sessionStorage.setItem("globalData", JSON.stringify(newData));
      }}
      id={val?.product_range}
    >
      <Row
        className={classes.card}
        style={{
          border: selected
            ? `1px solid ${brandProperties.principalColor}`
            : "1px solid #DCDCDC",
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {val.number === "1" ? (
            <img src={iconPjViePrivee} alt="" />
          ) : (
            <img src={iconPjViePriveePremium} alt="" />
          )}

          <span
            className={classes.nameProposition}
            style={{ color: brandProperties.secondColor }}
          >
            {val.number === "1" ? "pj vie privée" : "pj vie privée premium "}
          </span>
          <div style={{ display: "flex" }}>
            <p
              className={classes.tarif}
              style={{ color: brandProperties.secondColor }}
            >
              {" "}
              {checked ? val?.total_yearly : val?.total_monthly} €{" "}
            </p>
            <p
              className={classes.text}
              style={{ color: brandProperties.secondColor }}
            >
              {" "}
              {checked ? "/ année" : "/ mois"}
            </p>
          </div>
          {selected ? (
            <i
              className="fa-solid fa-circle-check"
              style={{
                fontSize: "21px",
                color: brandProperties.principalColor,
              }}
            ></i>
          ) : (
            <i
              className="fa-solid fa-circle-check"
              style={{ fontSize: "21px", color: "#ffffff" }}
            ></i>
          )}
        </Col>
      </Row>
    </Col>
  );
}

export default Card;
