/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./SendEmail.module.css";
import { Row, Col, Container } from "reactstrap";

import axios from "../../../utils/axios";

import Swal from "sweetalert2";
import EditorText from "./EditorText/EditorText";
import PreviewEmail from "./PreviewEmail/PreviewEmail";
import ParcoursContext from "../../../Context/ParcoursContext";
import { Alert, Snackbar } from "@mui/material";
import SolidButton from "../../../components/SolidButton/SolidButton";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function SendEmail(props) {
  const {
    modal,
    toggle,
    devis,
    saveToSession,
    setOpenbackdrop,
    setModal
  } = props;
  //eslint-disable-next-line
  const {
    proposition,
    destination,
    templateDevis,
    setEditorMessage,
    editorMessage,
    brandProperties,
  } = React.useContext(ParcoursContext);
  React.useEffect(() => {
    if (saveToSession) {
      saveToSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openf, setOpenf] = useState("");

  const sendDevisEmail = async () => {
    var regex = new RegExp(/^[\w-.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/);
    let validEmail = regex.test(destination?.split(" ")?.join(""));
    if (destination && validEmail) {
      setOpenbackdrop(true);


      let lienRepriseAdhesion = sessionStorage.getItem("repriseAdhesion");
      fetch(devis)
        .then((response) => response.blob())
        .then((blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        })
        .then((result) => {
          let prospectInfo = JSON.parse(
            sessionStorage.getItem("souscripteur")
          ).find((e) => e.relationship_rank === 1);
          const base64Data = result.split(",")[1];
          let template_to_send = templateDevis.replace(
            "*|FNAME|*",
            prospectInfo?.nom ? (prospectInfo?.nom).trim() : ""
          );
          template_to_send = template_to_send.replace(
            "*|LNAME|*",
            prospectInfo?.prenom ? (prospectInfo?.prenom).trim() : ""
          );

          template_to_send = template_to_send.replace(
            "[NOM GARANTIE]",
            proposition?.product_range === "PJ VIE PRIVE + OPTION"
              ? "Pj vie privée premium"
              : "Pj vie privée"
          );

          template_to_send = template_to_send.replace(
            "[--urlDevis--]",
            "https://as-solutions.fr/"
          );

          var doc_to_send = {
            "CG PJ VIE PRIVEE - AS SOLUTIONS V09-2022 .pdf":
              "https://sdn.as-solution.cloud.geoprod.com/static/uploads/CFDP docs/CG PJ VIE PRIVEE - AS SOLUTIONS V09-2022.pdf",
            "IPID VIE PRIVEE - AS SOLUTIONS V09-2022.pdf":
              "https://sdn.as-solution.cloud.geoprod.com/static/uploads/CFDP docs/IPID VIE PRIVEE - AS SOLUTIONS V09-2022.pdf",
            "Statuts mis à jour 16112022.pdf":
              "https://sdn.as-solution.cloud.geoprod.com/static/uploads/CFDP docs/Statuts mis à jour 16112022.pdf",
          };
          let docs_b64 = [];

          function fetchAndConvertToBase64(name, url) {
            return fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(blob);
                });
              })
              .then((result) => {
                let b64 = result.split(",")[1];
                return {
                  type: "b64",
                  name: name,
                  content: b64,
                };
              });
          }

          Promise.all(
            Object.entries(doc_to_send).map(([name, url]) =>
              fetchAndConvertToBase64(name, url)
            )
          ).then((results) => {
            docs_b64 = results;
            axios
              .post(
                `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/mailchimp/send_mail`,
                {
                  recipient: destination,
                  sender: "devis@as-solutions.fr",
                  subject: "Devis",
                  body: "",
                  html: template_to_send,
                  

                  attachments: [
                    {
                      type: "b64",
                      name: "Devis.pdf",
                      content: base64Data,
                    },
                    ...docs_b64,
                  ],
                }
              )
              .then((res) => {
                setOpenbackdrop(false);
                if (res.data.error === false) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "E-mail envoyé avec succès !",
                    color: "#000000",
                    showConfirmButton: false,
                    timer: 1700,
                  });
                }
              })
              .catch((err) => {
                setOpenbackdrop(false);
              });
          });
        });
    } else if (destination && !validEmail) {
      setOpenf("Veuillez vérifier votre adresse E-mail.");
    } else {
      setOpenf("Veuillez remplir votre adresse E-mail.");
    }
  };
  return (
    <>
      <Modal show={modal} fullscreen onHide={toggle}>
        <Modal.Header  style={{display:"flex", justifyContent:"end"}} >
        <FontAwesomeIcon
            icon={faXmark}
            id="BTN_close_modal"
            style={{ height: "22px", cursor: "pointer" }}
            onClick={() => {
              setModal(false);
            }}/>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className={classes.contain}>
            <Row>
              <Col xs="12" lg="6">
                <EditorText
                  message={editorMessage}
                  onChange={setEditorMessage}
                />
              </Col>
              <Col xs="12" lg="6">
                <PreviewEmail message={editorMessage} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <SolidButton
            id="BTN_envoyer_devis"
            onClick={sendDevisEmail}
            text="Envoyer"
            styles={{ border: `1px solid ${brandProperties.principalColor}` }}
            size="large"
            colorPrimary="#ffefef"
            textColor={brandProperties.principalColor}
          />
        </Modal.Footer>
        <Row>
          <Col>
            <Snackbar
              open={openf !== ""}
              autoHideDuration={3000}
              onClose={() => setOpenf("")}
            >
              <Alert
                onClose={() => setOpenf("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {openf}
              </Alert>
            </Snackbar>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default SendEmail;
