import React from "react";
import classes from "./Header.module.css";
// import RootFooter from "../RootFooter/RootFooter";
import SolidButton from "../../SolidButton/SolidButton";
import { Col, Row } from "reactstrap";
import ParcoursContext from "../../../Context/ParcoursContext";

const Header = () => {
  const { brandProperties, logo } = React.useContext(ParcoursContext);
  const openSupportUrl = () => {
    window.open(process.env.REACT_APP_SUPPORT_NEOPOLIS_URL, "_blank");
  };

  return (
    <Row className={classes.header}>
      <Col className={classes.headerCol}>
        <img
          src={logo}
          style={{ width: "150px", height: "90px" }}
          alt=""
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span className={classes.title} style={{color:brandProperties.principalColor}}>vie privée</span>
          <span className={classes.organismTitle} style={{color:brandProperties.principalColor}}>ASolutions (PJ)</span>
        </div>{" "}
        <SolidButton
          icon={<i className="fa-regular fa-comments-question" style={{fontSize:"21px"}}></i>}
          onClick={() => openSupportUrl()}
          text="Support"
          textColor="#282828"
          styles={{ fontWeight: 500, paddingInline: "2rem" }}
          size="large"
          colorPrimary="#26CB87"
          id="BTN_support"
        />
      </Col>
    </Row>
  );
};

export default Header;
